<template>
    <v-card max-width="374" class="mx-auto my-5" @click="$router.push({name: 'dashboard.social-graphics.show', params: {id: socialGraphic.id}})">
        <v-img :src="socialGraphicAvatar"/>
<!--        <v-avatar width="100%" height="250" tile  >

        </v-avatar>-->

<!--        <v-card-title class="pb-0" v-text="socialGraphic.name"   @click="$router.push({name: 'dashboard.social-graphics.show', params: {id: socialGraphic.id}})" />

        <v-card-text class="mt-4">
            <v-chip class="mx-1" v-for="(value, key) in groupByTypes" :key="JSON.stringify(value)">
                {{ key }}
            </v-chip>
        </v-card-text>-->
    </v-card>
</template>

<script>
    export default {
        name: "SocialGraphicCard",
        computed: {
            socialGraphicAvatar() {
                return this.socialGraphic.files[0].url
            },
            groupByTypes() {
                return this.$_.groupBy(this.socialGraphic.files, f => f.type)
            },
        },
        props: ['socialGraphic'],
        data: function () {
            return {
            }
        },
    }
</script>

<style scoped>

</style>
