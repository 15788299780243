<template>
    <user-dashboard-layout :loading="loading">
        <v-container>
            <hr>
            <v-row dense>
                <v-chip-group column active-class="primary--text">
                    <v-chip
                        exact
                        v-for="(tag, index) in tags"
                        @click="$router.push({query: {tag: tag.name}})"
                        :key="index"
                        v-text="tag.name"/>
                </v-chip-group>
            </v-row>
            <v-row>
                <timeframe-select/>
            </v-row>
            <v-row dense>
                <v-col sm="12" md="4" xl="3"  v-for="(item, i) in socialGraphics" :key="i">
                    <social-graphic-card :social-graphic="item"/>
                </v-col>
            </v-row>
        </v-container>
    </user-dashboard-layout>
</template>

<script>
    import Tag from "@/models/Tag";
    import SocialGraphic from "@/models/SocialGraphic";
    import UserDashboardLayout from "@/layouts/UserDashboardLayout";
    import SocialGraphicCard from "@/components/cards/SocialGraphicCard";
    import TimeframeSelect from "@/views/partials/TimeframeSelect";

    export default {
        name: "Index",
        components: {TimeframeSelect, SocialGraphicCard, UserDashboardLayout},
        data: function () {
            return {
                loading: false,
                socialGraphics: [],
                tags: []
            }
        },
        async mounted() {
            this.loading = true
            await this.getSocialGraphics();
            this.tags = await Tag.where('has_social_graphics', true).get();
            this.loading = false
        },
        methods: {
            async getSocialGraphics() {
                let query = SocialGraphic.where('industry_id', this.$auth.user().industry_id);

                if (this.$route.query.lastDays) {
                    query.where('lastDays', this.$route.query.lastDays);
                }

                this.socialGraphics = this.$route.query.tag
                    ?  await query.where('has_tag', this.$route.query.tag).include(['tags']).get()
                    :  await query.include(['tags']).get()
            }
        },
        watch: {
            "$route.query": async function (val, oldVal) {
                if (val !== oldVal) {
                    await this.getSocialGraphics();
                }
            }
        }
    }
</script>

<style scoped>

</style>
